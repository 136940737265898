import React from "react";

function BetaCard() {
  return (
    <div className="py-0.5 px-1 text-xs font-semibold text-gray-700 uppercase border border-gray-700">
      beta
    </div>
  );
}

export default BetaCard;
